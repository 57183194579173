import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { HeaderUserInfo } from './HeaderUserInfo';
import Logo from './logo.svg';

export const Header: React.FunctionComponent = () => {
  const location = useLocation();

  return (
    <header className="p-2 mb-3 border-bottom">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a href="/" className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
            <img src={Logo} alt="ConRoom.Exchange" className="logo" />
          </a>

          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li><Link to='/' className={`nav-link px-2 link-${location.pathname === '/' ? 'primary' : 'dark'}`}>Upcoming Events</Link></li>
            <li><Link to='/listings' className="nav-link px-2 link-dark">My Listings</Link></li>
            <li><Link to='/help' className="nav-link px-2 link-dark">Help &amp; Support</Link></li>
          </ul>

          <HeaderUserInfo />
        </div>
      </div>
    </header>
  );
};
