import { Role, User } from "../models/User";
import { GoogleIcon } from "./GoogleIcon";

interface Props {
  user: User;
}

export const UserAvatar: React.FunctionComponent<Props> = ({ user }) => {
  const icon = () => {
    let iconName = '';
    let className = '';
    let altText = '';

    switch (user.role) {
      case Role.Admin:
        iconName = 'shield';
        className = 'user-icon-admin';
        altText = 'ConRoom Exchange Admin';
        break;

      case Role.Moderator:
        iconName = 'shield';
        className = 'user-icon-mod';
        altText = 'ConRoom Exchange Moderator';
        break;

      case Role.PowerUser:
        iconName = 'grade';
        className = 'user-icon-poweruser';
        altText = 'Power User';
        break;
    }

    if (!iconName || !className) {
      return null;
    }

    return (
      <div className={className} title={altText}>
        <GoogleIcon icon={iconName} className='user-icon-bg' />
        <GoogleIcon icon={iconName} className='user-icon' />
      </div>
    )
  };

  return (
    <div className='user-avatar'>
      <img src={user.photo_url} className="rounded-circle" />
      {icon()}
    </div>
  );
};
