import { useCallback, useState } from 'react';
import TelegramLoginButton, { TelegramUser as TLBUser } from 'telegram-login-button';
import { User } from '../models/User';
import { GoogleIcon } from './GoogleIcon';
import { UserAvatar } from './UserAvatar';

export interface TelegramUser extends TLBUser {
  last_name?: string;
}

export const HeaderUserInfo: React.FunctionComponent = () => {
  const [user, setUser] = useState<User>();
  const doLogin = useCallback(async (user: TelegramUser) => {
    const { access_token } = await api.login(user);
    setUser(await api.getUserInfo(access_token));
  }, []);

  if (!user) {
    return (
      <div className="dropdown text-end user-info">
        <TelegramLoginButton
          requestAccess
          botName="ConRoomExchangeBot"
          dataOnauth={doLogin}
        />
      </div>
    );
  }

  return (
    <div className="dropdown text-end user-info">
      <a href="#" className="d-block link-dark text-decoration-none dropdown-toggle user-info-loggedin" id="dropdownUserInfo" data-bs-toggle="dropdown" aria-expanded="false">
        {/* <img src={user.photo_url} className="rounded-circle" />
        <div className='user-icon-admin'>
          <GoogleIcon icon='shield' className='user-icon-bg' />
          <GoogleIcon icon='shield' className='user-icon' />
        </div> */}
        <UserAvatar user={user} />
        {user.first_name} {user.last_name}
      </a>
      <ul className="dropdown-menu text-small" aria-labelledby="dropdownUserInfo">
        <li><a className="dropdown-item" href="#">Settings</a></li>
        <li><hr className="dropdown-divider" /></li>
        <li><a className="dropdown-item" href="#" onClick={() => setUser(undefined)}>Sign Out</a></li>
      </ul>
    </div>
  )
};
