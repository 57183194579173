import { Link } from 'react-router-dom';
import './Footer.scss';

export const Footer: React.FunctionComponent = () => {
  return (
    <footer className='footer mt-auto py-3'>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <span>
              &copy; 2021 <a href='https://github.com/crashdoom' target='_blank'>@Crashdoom</a>
            </span>
          </div>
          <div className='col text-end'>
            <span>
              <Link to='/policy/privacy'>Privacy Policy</Link> &#9642; <Link to='/policy/terms'>Terms of Service</Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};
