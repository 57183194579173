interface Props {
  icon: string;
  className?: string;
  outline?: boolean;
}

export const GoogleIcon: React.FunctionComponent<Props> = ({ className, icon, outline }) => {
  return (
    <span className={`material-icons${outline ? '-outline' : ''} ${className || ''}`}>
      {icon}
    </span>
  );
}