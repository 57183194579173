import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ConRoomExchangeApp.scss';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { BrowserRouter, Route } from 'react-router-dom';
import { Router } from './pages/Router';

export const App: React.FunctionComponent = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <BrowserRouter>
        <Header />
        <main className='flex-fill'>
          <div className="container">
            <Router />
          </div>
        </main>
        {/* <BanModal /> */}
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
