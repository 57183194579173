import axios, { AxiosRequestConfig } from 'axios';
import base64js from 'base64-js';
import { string } from 'yargs';
import { TelegramUser } from './components/HeaderUserInfo';
import { LoginToken, User } from './models/User';

export class APIClient {
  public async login(tgUser: TelegramUser): Promise<LoginToken> {
    return this.request<LoginToken>({
      method: 'post',
      url: '/users/login',
      data: {
        id: tgUser.id,
        json: this.base64Encode(tgUser),
      },
    });
  }

  public async getUserInfo(token: string): Promise<User> {
    return this.request({
      method: 'get',
      url: '/users/current',
      headers: {
        'authorization': 'Bearer ' + token,
      },
    });
  }

  public async request<T extends (object | void)>(opts: AxiosRequestConfig): Promise<T> {
    opts.url = 'http://localapi.conroom.exchange:3000' + opts.url;
    const { data } = await axios.request<T>(opts);
    return data;
  }

  private base64Encode(json: object): string {
    const bytes = new TextEncoder().encode(JSON.stringify(json));
    return base64js.fromByteArray(bytes);
  }
}
