import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AboutPage } from "./About";
import { MaintenancePage } from "./errors/Maintenance";
import { NotFoundPage } from "./errors/NotFound";

export const Router: React.FunctionComponent = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <MaintenancePage />
      </Route>
      <Route path='/about'>
        <AboutPage />
      </Route>
      <Route path='*'>
        <NotFoundPage />
      </Route>
    </Switch>
  );
};
